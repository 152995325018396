import React from "react";
import { useWatch } from "../../hooks/useWatch";
import { useCollection } from "../../hooks/useCollection";
import Config from "../../shared/Config";
import { addValueAtIndex, getItemIndex } from "../../shared/helpers";
const { dataSourceName } = Config();

export function useChatMessages({recipeintId}) {
  // const otherUserId = "6369ee5ef05bf458ba5db458"
  // const realmApp = useRealmApp();
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const collection = useCollection({
    cluster: dataSourceName,
    db: "soccerstats",
    collection: "chatMessages",
  });

  React.useEffect(() => {
    collection.aggregate([
      //   {
      //     $lookup:
      //       {
      //         from: "users",
      //         localField: "_partition",
      //         foreignField: "id",
      //         as: "seller"
      //       }
      //  },
      //  { "$match" : { "storeId" : store._id } },
      { "$match" : { "users" : { "$in": [recipeintId] }} },
      { $limit: 25 },
      { $sort: { "created_ts": 1 } }
    ]).then((res) => {
      console.log('useChatMessages.js: mongo', { res })
      setMessages(res);
      setLoading(false);
    });
  }, [collection, recipeintId]);

  // Use a MongoDB change stream to reactively update state when operations succeed
  useWatch(collection, {
    onInsert: (change) => {
      // console.log('useChatMessages onInsert', change.fullDocument)
      setMessages((oldMsgs) => {
        if (loading) {
          return oldMsgs;
        }
        const idx = getItemIndex(oldMsgs, change.fullDocument) ?? oldMsgs.length;
        if (idx === oldMsgs.length) {
          // insert at end of array
          return addValueAtIndex(oldMsgs, oldMsgs.length, change.fullDocument);
        } else {
          return oldMsgs;
        }
      });
    }
  });

  const insertChatMessage = async (draftMessage) => {
    if (draftMessage.users.length > 1) {
      try {
        await collection.insertOne(draftMessage);
      } catch (err) {
        if (err.error.match(/^Duplicate key error/)) {
          console.warn('The following error means that we tried to insert same _id multiple times.');
        }
        console.error(err);
      }
    } else {
      console.error('chat message requires 2 users');
    }
  };

  return {
    loading,
    messages,
    insertChatMessage
  };
}
