import { Send } from "@mui/icons-material";
import { Divider, Fab, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { BSON } from "realm-web";
import { useChatMessages } from "./useChatMessages";
// import { timeAgo } from "../../../hooks/util-hooks";
import { useRealmApp } from "../../components/RealmApp";
import ChatMessage from "./ChatMessage";

export default function Conversation({ chat }) {
    const realmApp = useRealmApp();
    if (!chat._id) {
        chat._id = new BSON.ObjectID().toString()
    }
    const conversationId = chat._id
    console.log('Conversation:',  {conversationId}, {chat});
    function getOtherUser() {
        return chat.message.usersInfo.find(u => u.id !== realmApp.currentUser.id);
    }
    const recipeintId = getOtherUser() ? getOtherUser().id : 111
    const { loading, messages, insertChatMessage } = useChatMessages({recipeintId});
    const messageInput = React.useRef();

    function getSenderInfo(msg) {
        return msg.usersInfo.find(u => u.id === msg.sender);
    }

    function getSide(message) {
        if (message.sender === realmApp.currentUser.id) {
            return "right";
        } else {
            return "left";
        }
    }

    function handleSend(e) {
        const chatMessage = {
            conversationId,
            users: [getOtherUser().id, realmApp.currentUser.id],
            usersInfo:[
                { id: realmApp.currentUser.id, name: realmApp.currentUser.customData?.fullname },
                getOtherUser()
            ],
            sender: realmApp.currentUser.id,
            recipient: getOtherUser().id,
            text: messageInput.current.value,
            created_ts: new Date()
        };
        console.log('## chat msg -->', {chatMessage});
        insertChatMessage(chatMessage);
        messageInput.current.value = ""
    }

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    return (
        <>
            {!loading &&
                <Grid sx={{ maxHeight: 460, overflowY: 'auto' }}>
                    {messages.map((m, idx) => (
                        <ChatMessage key={idx} side={getSide(m)} messages={[m]} sender={getSenderInfo(m)} />
                    ))}
                    <AlwaysScrollToBottom />
                </Grid>
            }

            <Divider sx={{ mt: 2 }} />
            <Grid container sx={{ mt: 2 }}>
                <Grid item xs={10}>
                    <TextField
                        inputRef={messageInput}
                        id="message-text"
                        name="message"
                        label="Type something"
                        fullWidth
                        multiline
                        rows={1.5}
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    alignItems="center"
                    justify="flex-end"
                >
                    <Fab
                        color="primary"
                        sx={{ ml: 1, mt: 2 }}
                        aria-label="add"
                        size="medium"
                        onClick={handleSend}
                    ><Send />
                    </Fab>
                </Grid>
            </Grid>
        </>
    );
}
