import { ArrowBack, Email } from "@mui/icons-material";
import { Button, Card, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { EmailLoginForm } from "../forms/EmailLoginForm";
import { FBLoginButton } from "../controls/FBLoginButton";
import { useRealmApp } from "../RealmApp";

export function LoginDialog({ open, setShowLogin, isSignupDefault = false }) {
    const realmApp = useRealmApp();
    const [emailLogin, setEmailLogin] = React.useState(false);
    const [isSignup, setIsSignup] = React.useState(isSignupDefault);

    const handleClose = (value) => {
        setShowLogin(false);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={false}>
            <DialogTitle>{isSignup ? "Sign up" : "Log In"}</DialogTitle>
            <DialogContent>
                {!emailLogin ?
                    <Card className="auth-card" variant="outlined">
                        <FBLoginButton
                            realmApp={realmApp}
                            isSignup={isSignupDefault}
                            setShowLogin={setShowLogin} />
                        <Typography sx={{ m: 3, textAlign: 'center' }}
                            variant="h6">OR</Typography>
                        <Button
                            startIcon={<Email />}
                            style={{ margin: 'auto', textAlign: 'center' }}
                           color="primary"
                            size="large"
                            sx={{mb:2}}
                            onClick={() => setEmailLogin(true)}
                            variant="contained">
                            Use Email
                        </Button>
                    </Card> :
                    <span>
                        <EmailLoginForm realmApp={realmApp} isSignup={isSignup} setIsSignup={setIsSignup} setShowLogin={setShowLogin} />
                        <Button onClick={() => {
                            setEmailLogin(false);
                            setIsSignup(false);
                        }}><ArrowBack /></Button>
                    </span>
                }
            </DialogContent>
        </Dialog>
    )
}