import React from "react";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function HomePage({currentUser}) {
  // const resize = `w_${1280},h_${1280}`
  // const imgBaseUrl = `https://res.cloudinary.com/dg6z5xnnv/image/upload/${resize}/v1673625867/swagshops`
  // const heroImg = `${imgBaseUrl}/photo-1673192191311-5e731b6d5545_skfhbi.jpg` // night-city-hero_eot3sw.jpg` 

  const colorA ='#ccc' // https://www.magicpattern.design/tools/css-backgrounds
  const colorB ='#EEE' // backColoer
  
  return (
    <main>
      <Box sx={{
        borderRadius:'9px',
        opacity:'0.9',
        // Hero gradient
        backgroundSize: '32px 56px',
        backgroundPosition:'0 0, 0 0, 16px 28px, 16px 28px, 0 0, 16px 28px',
        backgroundImage: `linear-gradient(30deg, ${colorA} 12%, transparent 12.5%, transparent 87%, ${colorA} 87.5%,${colorA}), linear-gradient(150deg, ${colorA} 12%, transparent 12.5%, transparent 87%, ${colorA} 87.5%, ${colorA}), linear-gradient(30deg, ${colorA} 12%, transparent 12.5%, transparent 87%, ${colorA} 87.5%, ${colorA}), linear-gradient(150deg, ${colorA} 12%, transparent 12.5%, transparent 87%, ${colorA} 87.5%, ${colorA}), linear-gradient(60deg, ${colorB} 25%, transparent 25.5%, transparent 75%, ${colorB} 75%, ${colorB}), linear-gradient(60deg, ${colorB} 25%, transparent 25.5%, transparent 75%, ${colorB} 75%, ${colorB})`,
        // Hero Image
        // backgroundImage: `url(${heroImg})`,
        pt: 8, pb: 6,
        bgcolor: 'background.paper'
        }}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="neutral.text"
            gutterBottom>
            Lorem ipsum...
          </Typography>
          <Typography variant="h5" align="center" color="secondary" paragraph>
            lorem ipsum to explain the mission...
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center">
            {!currentUser && 
              <Button component={Link} to={"/signup?redirect=/profile"} variant="contained">Sign-up now</Button>
            }
            <Button component={Link} to={"/about"} variant="outlined">More Info</Button>
          </Stack>
        </Container>
      </Box>
      <Container sx={{ py: 8 }} maxWidth="md">
        <Typography variant="h5" align="left" paragraph>
          Features
        </Typography>
        <Grid container spacing={4}>
          {/* {cards.map((card) => (
            <ImageGrid key={card.id} card={card} />
          ))} */}
        </Grid>
      </Container>
    </main>
  );
}
