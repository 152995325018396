import * as Realm from "realm-web";
import { Button } from "@mui/material";
import { Facebook } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";


export function FBLoginButton({ realmApp, setShowLogin, isSignup }) {
  const enableFBogin = false;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  function getLoginRedirectPath() {
    return searchParams.get('redirect');
  }

  return (
    <Button
      style={{ backgroundColor: '#1877F2', margin: 'auto' }}
      disabled={!enableFBogin}
      type="submit"
      variant="contained"
      startIcon={<Facebook />}
      size="large"
      onClick={(e) => {
        // The redirect URI should be on the same domain as this app and specified in the auth provider configuration.
        const redirectUri = `${origin}/auth/callback`; // "http://localhost:3000/auth/callback";
        // console.log({ redirectUri })
        const credentials = Realm.Credentials.facebook(redirectUri);
        // Calling logIn() opens a Facebook authentication screen in a new window.
        realmApp.logIn(credentials).then((user) => {
          // TODO: if (isSignup) set user as a "swagshop sign-up"
          // The logIn() promise will not resolve until you call `handleAuthRedirect()` from the new window after the user has successfully authenticated.
          console.log(`Logged in with id: {user.id}`, { user, isSignup });
          setShowLogin(false); // close Dialog
          if (getLoginRedirectPath()) {
            navigate(getLoginRedirectPath());
          }
        });
        // logs the user in. 
        // Realm.handleAuthRedirect();
      }}> Facebook Login</Button>
  )
}
