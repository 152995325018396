import { Link, Box, Typography } from "@mui/material";

export function Footer() {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
    <Typography
      variant="subtitle1"
      align="center"
      color="text.secondary"
      component="p"
    >
      Some business slogan here...
    </Typography>
    <Copyright />
  </Box>
  );
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        SoccerStats
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
