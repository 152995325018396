import React from "react";
import { useCollection } from "../../hooks/useCollection";
import { useRealmApp } from "../../components/RealmApp";
import Config from "../../shared/Config";
const { dataSourceName } = Config();

export function useChats() {
  // Set up a list of todos in state
  const realmApp = useRealmApp();
  const [chats, setChats] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  // Get a client object for the todo task collection
  const collection = useCollection({
    cluster: dataSourceName,
    db: "soccerstats",
    collection: "chatMessages",
  });

  // Fetch all todos on load and whenever our collection changes (e.g. if the current user changes)
  // const fields = {imageUrl: 1, title:1, price:1, _partition: 1, users:1 };

  React.useEffect(() => {
    const groupStage = {
      $group: {
        _id: "$conversationId",
        message: {
          $first: "$$ROOT"
        }
      }
    }
    
    collection.aggregate([
    //   {
    //     $lookup:
    //       {
    //         from: "users",
    //         localField: "_partition",
    //         foreignField: "id",
    //         as: "seller"
    //       }
    //  },
    //  { "$match" : { "storeId" : store._id } },
    //  { "$match" : { "status" : { "$in": ["active","sold"] }} }
    { $limit: 20 },
    groupStage
   ]).then((res) => {
      console.log('useChats.js: mongo', {res})
      setChats(res.reverse());
      setLoading(false);
    });
  }, [collection]);

  const insertChat = async (draftChat) => {
    if (draftChat.imageUrl) {
      draftChat._partition = realmApp.currentUser.id;
      try {
        await collection.insertOne(draftChat);
      } catch (err) {
        if (err.error.match(/^Duplicate key error/)) {
          console.warn(
            `The following error means that we tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
          );
        }
        console.error(err);
      }
    }
  };

  const updateChat = async (chat) => {
    console.log({chat})
    try {
      await collection.updateOne(
        {_id: chat._id},
        { $set: chat }
      );
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    }
  };

  // const deleteListing = async (listing) => {
  //   await collection.deleteOne({ _id: listing._id });
  // };

  return {
    loading,
    chats,
    insertChat,
    updateChat,
    // deleteListing
  };
}
