import React from "react";
import {
  Container,
  Card,
  Typography,
  CardHeader,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { useUsers } from "../../../hooks/useUsers";
import ProfileForm from "./ProfileForm";

export function ProfilePage({ store }) {
  const { upsertUser, user, loading, currentUser } = useUsers();

  return (
    <Container maxWidth="sm" disableGutters={true} className="main-container">
      {loading ? <LinearProgress /> :
        <>
          <ProfileForm
            user={user}
            store={store}
            currentUser={currentUser}
            upsertUser={upsertUser}
          />
        </>}
    </Container>)
}
